@import url("https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap");
* {
  box-sizing: border-box;
}
body {
  font-family: Rubik, sans-serif;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  padding: 0;
}
input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Ubuntu, Arial, sans-serif;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 150%;
}
input[type="text"] {
  padding: 8px;
  border: 1px solid #aaa;
}
a {
  cursor: pointer;
  color: #ee1696;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
button {
  cursor: pointer;
  font-size: 100%;
}
button {
  background: #ee1696;
  color: white;
  border: 0;
  padding: 10px 30px;
}

hr {
  border-top: 1px solid #eee;
  border-bottom: 0px;
}

#App {
  overflow-x: hidden;
}

@media all and (max-width: 800px) {
  img.responsive {
    max-width: 100% !important;
    width: auto !important;
    max-height: auto !important;
    float: none !important;
    margin: 20px auto !important;
    clear: both;
    display: block;
  }
}
